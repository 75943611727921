<template>
<div class="ProgressQuery" style="margin: -15px -15px">
<!--  计划进展查询   -->
  <div class="top">
    <div class="top-child">
      <div class="fontSizeTitle">计划总数</div>
      <div class="fontSizeCount">8</div>
    </div>
    <div class="top-child">
      <div class="fontSizeTitle">已完成计划数</div>
      <div class="fontSizeCount">8</div>
    </div>
    <div class="top-child">
      <div class="fontSizeTitle">未完成计划数</div>
      <div class="fontSizeCount">8</div>
    </div>
  </div>
  <div class="content">
    <div class="content-top">
      <div style="width: 60%;padding-left: 20px">计划列表</div>
      <div style="width: 40%;display: flex;justify-items: right">
        <div>
          <a-radio-group
              style="margin-left: 5px"
              v-model="radioValue"
              @change="onChange">
            <a-radio-button value="">
              全部
            </a-radio-button>
            <a-radio-button value="1">
              进行中
            </a-radio-button>
            <a-radio-button value="0">
              已完成
            </a-radio-button>
          </a-radio-group>
        </div>
        <div style="margin-left: 10px">
          <a-input-search placeholder="请输入" style="width: 200px" @search="onSearch" />
        </div>
    </div>
  </div>
    <!--      表格     -->
    <div class="content-table">
      <div class="tableList" v-for="item in tableList" :key="item.id">
        <div style="display: flex">
          <div>
            <img class="img" src="../../../assets/img.png"/>
          </div>
          <div class="titles">
            <div class="titles-font">{{ item.title }}</div>
            <div class="color-font">计划数量：{{ item.palnCount }}场  已完成：{{ item.overCount }}场</div>
          </div>
        </div>
        <div class="marginTop-table">
          <div class="color-font">业务负责人</div>
          <div class="color-font">{{ item.person }}</div>
        </div>
        <div class="marginTop-table">
          <div class="color-font">计划时间</div>
          <div class="color-font">{{ item.time }}</div>
        </div>
        <div style="margin-top: 10px;display: flex;">
          <a-progress size="small" v-if="item.isFalse" style="width: 150px" :percent="item.count" status="exception" />
          <a-progress size="small" v-else style="width: 150px" :percent="item.count" />
          <div style="margin-left: 30px">
            <a>查看</a>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 50px;">
      <div style="float: right;margin-right: 20px;margin-top: 15px">
        <a-pagination
            show-quick-jumper
            :default-current="pageNo"
            @change="listChange"
            :total="tableCount" />
      </div>
    </div>
</div>
</div>
</template>

<script>
export default {
name: "ProgressQuery",
  data(){
  return{
    radioValue:'',
    tableList:[
      {
        img:'https://img2.baidu.com/it/u=1395980100,2999837177&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=675',
        id:1,
        title:'2023年xxxxx客户直播计划',
        palnCount:1,
        overCount:1,
        person:'宿建成',
        time:'2016-06-16～2017-06-16',
        count:50,
      },
      {
        img:'https://img2.baidu.com/it/u=1395980100,2999837177&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=675',
        id:2,
        title:'2023年xxxxx客户直播计划',
        palnCount:1,
        overCount:1,
        person:'宿建成',
        time:'2016-06-16～2017-06-16',
        count:100,
      }, {
        img:'https://img2.baidu.com/it/u=1395980100,2999837177&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=675',
        id:3,
        title:'2023年xxxxx客户直播计划',
        palnCount:1,
        overCount:1,
        person:'宿建成',
        time:'2016-06-16～2017-06-16',
        count:10,
        isFalse:true,
      },
    ],
    tableCount:2,
    pageNo:1,
  }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"直播计划进展查询")
  },
  methods:{
    onChange(){

    },
    onSearch(){

    },
    listChange(pageNo){
      this.pageNo = pageNo
    },
  }
}
</script>

<style lang="scss">
.ProgressQuery{
  height: 100%;
.top{
  height: 120px;
  border-bottom: 10px solid #f5f5f5;
  display: flex;
  justify-content: space-around;
  .top-child{
    height: 100%;
    padding-top: 40px;
    text-align: center;
    .fontSizeCount{
      font-size: 18px;
      color: #333333;
    }.fontSizeTitle{
      font-size: 14px;
      color: #a3a0a0;
    }
  }
}
.content{
  .content-top{
    height: 70px;
    display: flex;
    padding-top: 20px;
  }
  .content-table{
    width: 94%;margin: auto;
    .tableList{
      margin-top: 20px;
      height: 60px;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      justify-content: space-between;
    }
    .img{
      width: 50px;height: 50px;border: 1px solid #f5f5f5;
    }
    .titles{
      margin-left: 10px;margin-top: 5px;
      .titles-font{
        font-size: 16px;font-weight: bold;color: #333333;
      }
    }
    .marginTop-table{
      margin-top: 5px
    }
    .color-font{
      font-size: 14px;color: #a3a0a0;
    }
  }
}
}
</style>